.schoolLogo {
    width: 200px;
    height: 200px;
}

.contain {
    object-fit: contain;
}

.schoolLogoHidden {
    width: 0px;
    height: 0px;
}
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&family=Poppins:wght@500;600&display=swap');

html, body {
    margin: 0; 
    padding: 0;
    font-family: 'Roboto', sans-serif;
}

.background {
    background-image: url('../images/background.svg');
    width: 100vw;
    height: 100vh;
    background-size: cover;
    background-repeat:no-repeat;
}

.container {
    text-align: center;

    width: 386px;
    padding: 32px;
    border-radius: 8px;
    background: white;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-shadow: 0px 18px 30px rgba(9, 30, 67, 0.03);
}

.header {
    width: 100%;
    height: 58px;
    background: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0px 18px 30px 0px #091E4308;
}

.logo {
    padding-left: 16px;
}

.logout {
    padding-right: 16px;
    display: flex;
    align-items: center;
}

.username {
    padding-left: 12px;
    padding-right: 16px;
    font-weight: 400;
    font-size: 14px;
}

.button {
    width: 62px;
    height: 32px;
    background: #0075DB;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-weight: 500;
    font-size: 12px;
    border-radius: 6px;
    cursor: pointer;
}

.notification {
    height: 42px;
    background: #E1F5DC;
    border-bottom: 1px solid #76de6d;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    color: #1d7335;
    position: absolute;
    width: 100%;
    font-weight: 500;
    line-height: 18px;
    z-index: 100;
}

.notificationIcon {
    padding-right: 10px;
}

.closeIcon {
    padding-left: 10px;
    cursor: pointer;
}
.flexCentered {
    display: flex;
    align-items: center;
    justify-content: center;
}

.title {
    color: #091E42;
    font-size: 22px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    line-height: 28px;
}

.subtitle {
    font-size: 16px;
    color: #42526E;
    overflow-wrap: break-word;
    max-width: 386px;
}

.subtitleEmphasis {
    font-weight: 500;
    color: #091E42;
}

.lightButton {
    margin-top: 16px;
    color: #094C99;
    background: #E7F9FF;
    width: 142px;
    font-weight: 500;
    font-family: Roboto, sans-serif;
}

.plainLink {
    text-decoration: none;
}

.bypassContainer {
    margin-top: 24px;
    border-radius: 6px;
    width: 386px;
    height: 118px;
    background: #E3FAF8;
    flex-direction: column;
    justify-content: space-evenly;
}

.bypassTitle {
    color: #091E42;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
}

.bypassContents {
    display: flex;
    flex-direction: column;
}

.bypassInput {
    border: 1px solid #C1C7D0;
    box-sizing: border-box;
    border-radius: 6px;
    width: 230px;
    height: 36px;
    padding: 8px 12px;
    font-family: Roboto, sans-serif;
    margin-right: 8px;
}

.bypassInputError {
    background-color: #FFE8EA;
    border: 1px solid #F4767F;
}

.infoText {
    padding-top: 4px;
    font-size: 12px;
    align-self: flex-start;
}

.successText {
    color: #177335;
}

.errorText {
    color: #AD1F1F;
}

@keyframes spin {
    to { -webkit-transform: rotate(360deg); }
}
@-webkit-keyframes spin {
    to { -webkit-transform: rotate(360deg); }
}

.spinner {
    display: inline-block;
    border: 2px solid rgba(255,255,255,.3);
    border-radius: 50%;
    border-top-color: #fff;
    animation: spin 1s ease-in-out infinite;
    -webkit-animation: spin 1s ease-in-out infinite;
}

.unblock-spinner {
    width: 12px;
    height: 12px;
}

.unblock {
    width: 68px;
}

#message {
    text-align: initial;
    font-size: 14px;
    overflow-y: auto;
    max-height: 100px;
}

.downvoteText {
    font-size: 14px;
    font-weight: 400;
    color: #42526E;
}

.link {
    color: #174c99;
    cursor: pointer;
    font-size: 14px;
}

.detailTable {
    border: 1px solid #EBECF0;
    border-radius: 10px;
    width: 386px;
    text-align: start;
    font-size: 14px;
    margin-top: 24px;
    margin-bottom: 24px;
}

.detailTable td {
    height: 40px;
    overflow-wrap: break-word;
    max-width: 171px;
    padding-top: 4px;
    padding-bottom: 4px;
}

.detailTable tr:nth-child(odd) {
    background: #F4F5F799;
}

.detailTable td:nth-child(odd) {
    font-weight: 500;
    color: #091E42;
    white-space: nowrap;
    width: 1px;
    padding-right: 30px;
    padding-left: 12px;
}

.detailTable td:nth-child(even) {
    color: #42526E;
    padding-right: 12px;
}

.detailSpinner {
    width: 54px;
    height: 54px;
    border-top-color: #0075DB;
    margin-top: 72px;
    margin-bottom: 64px;
    border-width: 3px;
}